export enum RoutePath {
    // Auth:

    LOGIN = '/login',
    SIGNUP = '/registration',
    RESET_PASSWORD = '/reset-password',
    REGISTRATION = '/start',
    SELLING = '/start/selling',
    REGISTRATION_STORE = '/start/store',
    REGISTRATION_CATALOG = '/start/catalog',
    REGISTRATION_CATALOG_ADD = '/start/catalog/add',
    REGISTRATION_CATALOG_EDIT = '/start/catalog/categories/:categoryId/products/:productId',
    ONBOARDING_REGISTRATION = '/start/registration',
    REGISTRATION_SETTINGS = '/start/settings',
    REGISTRATION_SUBSCRIPTION = '/start/subscription',
    REGISTRATION_SUCCESS = '/start/success',
    REGISTRATION_SETTINGS_PICKUP = '/start/settings/pickup',
    REGISTRATION_SETTINGS_DELIVERY = '/start/settings/delivery',
    REGISTRATION_SETTINGS_CASH = '/start/settings/cash',
    REGISTRATION_SETTINGS_CARD = '/start/settings/card',
    REGISTRATION_SETTINGS_ONLINE = '/start/settings/online',
    REGISTRATION_FAKE = '/start/fake',

    // Vendor:
    VENDOR = '/vendor',
    VENDOR_MENU = '/vendor/menu',
    VENDOR_MENU_PROFILE = '/vendor/menu/profile',
    VENDOR_MENU_SUBSCRIPTION = '/vendor/menu/subscription',
    VENDOR_MENU_SETTINGS = '/vendor/menu/storeInfo',
    VENDOR_MENU_MARKETING_MATERIALS = '/vendor/menu/marketing',
    VENDOR_MENU_SETTINGS_PICKUP = '/vendor/menu/storeInfo/pickup',
    VENDOR_MENU_SETTINGS_DELIVERY = '/vendor/menu/storeInfo/delivery',
    VENDOR_MENU_SETTINGS_CASH = '/vendor/menu/storeInfo/cash',
    VENDOR_MENU_SETTINGS_CARD = '/vendor/menu/storeInfo/card',
    VENDOR_MENU_SETTINGS_ONLINE = '/vendor/menu/storeInfo/online',
    VENDOR_MENU_STORE_INFO = '/vendor/menu/storeInfo',
    VENDOR_DASHBOARD = '/vendor/dashboard',
    VENDOR_CATALOG = '/vendor/catalog',
    VENDOR_CATALOG_ADD = '/vendor/catalog/add',
    VENDOR_CATALOG_EDIT = '/vendor/catalog/categories/:categoryId/products/:productId',
    VENDOR_PROMO = '/vendor/promo',
    VENDOR_PROMO_ADD = '/vendor/promo/add',
    VENDOR_PROMO_EDIT = '/vendor/promo/edit/:promoId',
    VENDOR_STORE_TAB = '/vendor/store',

    // Vendor Orders:
    ORDERS = '/vendor/orders',
    ORDERS_NEW = '/vendor/orders/new',
    ORDERS_ACTIVE = '/vendor/orders/active',
    ORDERS_CLOSED = '/vendor/orders/closed',
    ORDERS_NEW_DIALOG = '/vendor/orders/new/:orderId',
    ORDERS_NEW_DIALOG_EDIT = '/vendor/orders/new/:orderId/edit',
    ORDERS_ACTIVE_DIALOG = '/vendor/orders/active/:orderId',
    ORDERS_CLOSED_DIALOG = '/vendor/orders/closed/:orderId',

    // Store:
    STORE = '/:storeSlug',
    STORE_INFO = '/:storeSlug/info',
    STORE_PRODUCT = '/:storeSlug/catalog/categories/:categoryId/products/:productId',
    STORE_PRODUCT_CHECKOUT = '/:storeSlug/catalog/categories/:categoryId/products/:productId/checkoutError',
    STORE_CATALOG_TAB = '/:storeSlug/catalog',
    STORE_INFO_TAB = '/:storeSlug/info',
    STORE_PROMOTIONS_TAB = '/:storeSlug/promotions',
    STORE_GALLERY_TAB = '/:storeSlug/gallery',
    STORE_SERVICES_TAB = '/:storeSlug/services',
    STORE_DELIVERY_TAB = '/:storeSlug/delivery',
    STORE_PAYMENT_TAB = '/:storeSlug/payment',
    STORE_REQUISITES_TAB = '/:storeSlug/requisites',
    STORE_CONTACTS_TAB = '/:storeSlug/contacts',

    // Feed:
    FEED = '/',

    // Legal:
    LEGAL = '/legal',

    // About:
    ABOUT = '/about',
    BUYERS = '/buyers',
    SELLERS = '/sellers',
    DELIVERY = '/delivery',
    PAYMENTS = '/payments',
    CONTACTS = '/contacts',
    BLOG = '/blog',

    //Blogs:
    BLOGPAGE = '/kak-sozdat-internet-magazin-besplatno-na-any-market',

    //Operations
    ADMIN_REPORT = '/admin/report',
}
