import React, { FC, useEffect, useState } from 'react';

import { getLocalStorageItem, LocalStorageKey, setLocalStorageItem } from 'core/storage/storage';
import { getJwt } from 'core/auth/auth';
import { ReactComponent as CloseIcon } from 'assets/icons/main-16/times--16.svg';
import i18n from '../../../i18n';
import { Icon } from '../Icon/Icon';
import css from './CookiesBanner.module.css';

export const CookiesBanner: FC = () => {
    const [shouldRender, setShouldRender] = useState(false);
    const { t } = i18n.useTranslation();

    useEffect(() => {
        setShouldRender(!(getLocalStorageItem(LocalStorageKey.COOKIES_ACCEPTED) || getJwt()));
        const data = getLocalStorageItem(LocalStorageKey.COORDINATES);
        document.cookie = `coordinates=${JSON.stringify(data || {})};`;
    }, []);

    if (!shouldRender) {
        return null;
    }

    setTimeout(() => {
        setLocalStorageItem(LocalStorageKey.COOKIES_ACCEPTED, true);
        setShouldRender(false);
    }, 5000);

    return (
        <div className={css.cookie}>
            <div className={css.container}>{t('common:cookie.text')}</div>
            <div
                className={css.icon}
                onClick={() => {
                    setLocalStorageItem(LocalStorageKey.COOKIES_ACCEPTED, true);
                    setShouldRender(false);
                }}
            >
                <Icon component={CloseIcon} size={16} svgSize={16} />
            </div>
        </div>
    );
};
