import { clearLocalStorageWithException, LocalStorageKey, validateLocalStorage } from '../storage/storage';
import { RoutePath } from 'RoutePath';
import browserCookies from 'browser-cookies';
import { UserRole } from 'types';

const JWT_COOKIE_NAME = 'jwt';
const ROLE_COOKIE_NAME = 'role';

export const getJwt = (): string | null => {
    return typeof window === 'undefined' ? null : browserCookies.get(JWT_COOKIE_NAME);
};

export const setJwt = (jwt: string): void => {
    if (typeof window !== 'undefined') {
        browserCookies.set(JWT_COOKIE_NAME, jwt, { samesite: 'Lax', expires: 365 });
    }
};

export const removeJwt = (): void => {
    if (typeof window !== 'undefined') {
        browserCookies.erase(JWT_COOKIE_NAME);
    }
};

export const getRole = (): UserRole | null => {
    const cookieValue = typeof window === 'undefined' ? null : browserCookies.get(ROLE_COOKIE_NAME);
    if (cookieValue === UserRole.ADMINISTRATOR) {
        return UserRole.ADMINISTRATOR;
    }
    if (cookieValue === UserRole.VENDOR) {
        return UserRole.VENDOR;
    }
    if (cookieValue === UserRole.CUSTOMER) {
        return UserRole.CUSTOMER;
    }
    return null;
};

export const setRole = (role: UserRole): void => {
    if (typeof window !== 'undefined') {
        browserCookies.set(ROLE_COOKIE_NAME, role, { samesite: 'Lax', secure: true, expires: 365 });
    }
};

export const removeRole = (): void => {
    if (typeof window !== 'undefined') {
        browserCookies.erase(ROLE_COOKIE_NAME);
    }
};

export const loginAs = (role: UserRole, onChangeRole?: () => void) => {
    setRole(role);
    validateLocalStorage();
    if (!onChangeRole) {
        window.location.reload();
    } else {
        onChangeRole();
    }

    if (role === UserRole.VENDOR) {
        window.location.href = RoutePath.VENDOR_DASHBOARD;
    }
};

export const logout = (path?: RoutePath) => {
    removeJwt();
    removeRole();
    clearLocalStorageWithException([
        LocalStorageKey.ADDRESS,
        LocalStorageKey.ADDRESS_CHANGED_BY_USER,
        LocalStorageKey.COORDINATES,
        LocalStorageKey.FEED_VISITED,
        LocalStorageKey.COOKIES_ACCEPTED,
        LocalStorageKey.DISABLE_ADD_TO_HOME_SCREEN,
    ]);
    if (path) {
        window.location.assign(path);
    } else {
        window.location.reload();
    }
};
