import React, { FC } from 'react';
import { SvgIcon } from '@material-ui/core';

export interface IconProps {
    component: any;
    // Optional:
    svgSize?: number;
    svgString?: string;
    color?: string;
    size?: number;
    className?: string;
    style?: Object;
    fill?: string;
}

export const Icon: FC<IconProps> = (props: IconProps) => {
    const { component, svgSize, size, className, color = '#243143', style = {}, svgString, fill } = props;
    return (
        <SvgIcon
            className={className}
            style={{ width: size, height: size, color, fill, ...style }}
            component={component}
            viewBox={svgString || `0 0 ${svgSize} ${svgSize}`}
        />
    );
};
