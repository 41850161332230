import { UserRole } from 'types';
import { getJwt, getRole, removeJwt, removeRole, setRole } from '../auth/auth';

const VERSION = 2;

export enum LocalStorageKey {
    VERSION = 'version',
    WELCOME_DIALOG_CLOSED = 'welcomeDialogClosed',
    PHONE = 'phone',
    ADDRESS = 'address',
    ADDRESS_CHANGED_BY_USER = 'addressChangedByUser',
    COORDINATES = 'coordinates',
    STANDARD_ADDRESS_DATA = 'standardAddressData',
    CART_ID = 'cartId',
    CART_ORDER_ID = 'cartOrderId',
    COOKIES_ACCEPTED = 'cookiesAccepted',
    FEED_VISITED = 'feedVisited',
    STORE_ID = 'storeId',
    DISABLE_ADD_TO_HOME_SCREEN = 'disableAddToHomeScreen',
    PAYMENT_INTENT = 'paymentIntent',
}

const isServer = typeof window === 'undefined';

export const validateLocalStorage = () => {
    if (!isServer) {
        const actualVersion = getLocalStorageItem(LocalStorageKey.VERSION);
        if (VERSION !== actualVersion) {
            localStorage.clear();
            setLocalStorageItem(LocalStorageKey.VERSION, VERSION);
        }
        const address: { address: string } = getLocalStorageItem(LocalStorageKey.ADDRESS);
        const coordinates: { lat: number; lon: number } = getLocalStorageItem(LocalStorageKey.COORDINATES);
        if (!address || !address.address || !coordinates || !coordinates.lat || !coordinates.lon) {
            removeLocalStorageItem(LocalStorageKey.ADDRESS);
            removeLocalStorageItem(LocalStorageKey.COORDINATES);
            removeLocalStorageItem(LocalStorageKey.STANDARD_ADDRESS_DATA);
            removeLocalStorageItem(LocalStorageKey.ADDRESS_CHANGED_BY_USER);
        }
        const jwt = getJwt();
        const hasPhone = hasLocalStorageItem(LocalStorageKey.PHONE);
        if (!jwt || !hasPhone) {
            removeJwt();
            removeRole();
            removeLocalStorageItem(LocalStorageKey.PHONE);
            return;
        }
        const role = getRole();
        if (role === UserRole.VENDOR) {
            removeLocalStorageItem(LocalStorageKey.CART_ORDER_ID);
            removeLocalStorageItem(LocalStorageKey.CART_ID);
        } else if (role !== UserRole.CUSTOMER) {
            setRole(UserRole.CUSTOMER);
        }
    }
};

export const getLocalStorageItem = <T>(key: LocalStorageKey): T => {
    if (!isServer) {
        try {
            const contents = localStorage.getItem(key);
            return JSON.parse(contents);
        } catch (e) {
            localStorage.removeItem(key);

            return null;
        }
    }
    return null;
};

export const getSessionStorageItem = <T>(key: LocalStorageKey): T => {
    if (!isServer) {
        try {
            const contents = sessionStorage.getItem(key);
            const base64 = Buffer.from(contents).toString('base64');
            if (key === 'coordinates') {
                document.cookie = `coordinates=${contents};`;
                document.cookie = `coordinatesBase=${base64};`;
            } else if (key === LocalStorageKey.ADDRESS) {
                document.cookie = `${key}=${base64};`;
            }
            return JSON.parse(contents);
        } catch (e) {
            sessionStorage.removeItem(key);

            return null;
        }
    }
    return null;
};

export const hasLocalStorageItem = (key: LocalStorageKey): boolean => {
    if (!isServer) {
        try {
            const contents = localStorage.getItem(key);
            const data = JSON.parse(contents);
            const base64 = Buffer.from(contents).toString('base64');
            if (key === 'coordinates') {
                document.cookie = `${key}=${contents};`;
                document.cookie = `coordinatesBase=${base64};`;
            } else if (key === LocalStorageKey.ADDRESS) {
                document.cookie = `${key}=${base64};`;
            }
            return !!data;
        } catch (e) {
            localStorage.removeItem(key);
            return false;
        }
    }
    return null;
};

export const setLocalStorageItem = <T>(key: LocalStorageKey, item: T): void => {
    const contents = JSON.stringify(item);
    if (!isServer) {
        localStorage.setItem(key, contents);
        const base64 = Buffer.from(contents).toString('base64');
        if (key === 'coordinates') {
            document.cookie = `${key}=${contents};`;
            document.cookie = `coordinatesBase=${base64};`;
        } else if (key === LocalStorageKey.ADDRESS) {
            document.cookie = `${key}=${base64};`;
        }
    }
};

export const setSessionStorageItem = <T>(key: LocalStorageKey, item: T): void => {
    const contents = JSON.stringify(item);
    if (!isServer) {
        sessionStorage.setItem(key, contents);
    }
};
export const removeLocalStorageItem = (key: LocalStorageKey): void => {
    if (!isServer) {
        localStorage.removeItem(key);
    }
    return null;
};

export const clearLocalStorageWithException = (exceptKeys?: LocalStorageKey[]): void => {
    if (!isServer) {
        const data: Record<string, string> = exceptKeys?.reduce(
            (acc, key) => ({ ...acc, [key]: localStorage.getItem(key) }),
            {},
        );
        localStorage.clear();
        setLocalStorageItem(LocalStorageKey.VERSION, VERSION);
        Object.entries(data).forEach(([key, value]) => localStorage.setItem(key, value));
    }
};
